<template>
  <v-main>
    <!-- <layout-drawer /> -->

    <v-responsive class="mx-auto overflow-visible">
      <v-container fluid class="pa-0">
        <v-responsive class="overflow-visible" min-height="90vh">
          <router-view></router-view>
        </v-responsive>
        <ProductListDialog />
      </v-container>
    </v-responsive>
    <core-snack-bar></core-snack-bar>
  </v-main>
</template>

<script>
import ProductListDialog from '@/components/product/ProductListDialog.vue';

export default {
  name: 'LayoutView',

  components: {
    ProductListDialog
  }
};
</script>
